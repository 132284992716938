
import React from "react"

import ListmanagerProducttagPage from "../../components/negosyonow/listmanagers/producttag"

const FeatureditemsPage = ({location}) => {
	return <ListmanagerProducttagPage
				location={location}
				allowadd={true}
				tagid={41}
			/>
}


export default FeatureditemsPage;
